//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FightCampLogo from '@/shared/components/FightCampLogo.vue';

export default {
  name: 'ConfirmationNavBar',
  components: {
    FightCampLogo,
  },
  methods: {
    triggerChat() {
      // eslint-disable-next-line no-undef
      openDriftChat();
    },
  },
};
