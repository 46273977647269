export default function openDriftChat() {
  // eslint-disable-next-line no-undef
  const { $analytics } = useNuxtApp();

  if (process.client && !window.drift) {
    // eslint-disable-next-line no-undef
    $nuxt.context.app.$ecommAnalytics.triggerDrift();
  }
  if (process.client && window.drift) {
    window.drift.on('ready', (api, event) => {
      const status = event.data.isOnline ? 'online' : 'offline';
      api.toggleChat();
      api.on('chatOpen', () => {
        document.body.classList.remove('hide-drift');
        $analytics.trackEvent('Chat', { action: 'Open', status });
      });

      window.drift.on('chatClose', () => {
        document.body.classList.add('hide-drift');
        $analytics.trackEvent('Chat', { action: 'Close', status });
      });
    });
  }
}
